import React, { useEffect, useState } from "react";
import Image from "next/image";
import Local from "../components/ImageLoader";
import styles from "./Footer.module.css";
import { GetFooterMenu } from "../components/api";
import Link from "next/link";
import CopyRight from "./Footer/CopyRight";
import themes from "../themes/themes";
import { useTranslation } from "../context/TranslationContext";
const Footer = ({ ssr }) => {
  const { footerPaymentLinkButtonText, pay } = useTranslation();
  const [Menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GetFooterMenu()
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error);
          setMenu([]);
        } else {
          setMenu(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);

  const FooterLinkGroup = ({ val }) => {
    return (
      <div className={"lg:flex flex-col hidden " + styles.FooterLinkGroup}>
        <span className={styles.FooterTitle}>{val.name}</span>
        <div className="flex flex-wrap justify-between gap-y-5">
          {val.items.map((i, k) => {
            const attr = {};
            if (i.nofollow == 0) attr["rel"] = "nofollow";
            return (
              <Link href={i.url} key={k}>
                <a title={i.title} {...attr}>
                  {i.name}{" "}
                </a>
              </Link>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        "bg-brand-gray-7 bg-no-repeat bg-left " +
        (ssr.RoutingTypeId == "Home" ? "pt-40" : "pt-10") +
        " " +
        themes.footerBgLogo
      }
    >
      <div className="container lg:py-14 pb-8 flex flex-col">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-5/12">
            <div className="hidden lg:block">
              <Image
                src={themes.logo}
                width={themes.footerLogoWidth}
                height={50}
                loader={Local}
                alt={process.env.NEXT_PUBLIC_SITENAME + " Logo"}
              />
            </div>
            <div className="flex mt-6 justify-between">
              {Menu ? (
                <div className="flex flex-col">
                  <span className="text-xs text-brand-gray-9 font-semibold mr-1">
                    {Menu.static.email} <br />
                    {Menu.static.adres}
                  </span>
                  <span className="text-lg text-brand-gray-9 mt-2 leading-9 ml-1">
                    <b>
                      {Menu.static.telefon.replace(
                        Menu.static.telefon.substr(
                          Menu.static.telefon.length - 9
                        ),
                        ""
                      )}
                    </b>
                    {Menu.static.telefon.substr(Menu.static.telefon.length - 9)}
                  </span>
                </div>
              ) : null}

              <Link
                href={
                  "https://etbis.eticaret.gov.tr/sitedogrulama/6067570258707468"
                }
              >
                <a target={"_blank"} rel={"nofollow"} className="lg:hidden">
                  <Image
                    src="/icons/etbis.svg"
                    width={79}
                    height={90}
                    loader={Local}
                    alt={"Etbis QR Kod"}
                  />
                </a>
              </Link>
            </div>

            <div className="flex justify-between flex-col mm:flex-row lg:hidden items-center mm:items-start mt-5 mm:mt-0 gap-y-3 mm:gap-y-0">
              <div className="flex gap-x-3 items-center">
                <span className="text-black font-bold text-13">
                  Takipte Kal
                </span>
                {Menu ? (
                  <>
                    {Menu.social.instagram && (
                      <Link href={Menu.social.instagram}>
                        <a
                          title={
                            process.env.NEXT_PUBLIC_SITENAME + " Instagram"
                          }
                          target={"_blank"}
                          rel="nofollow"
                        >
                          <div className="w-9 h-9 relative rounded-full bg-white p-2">
                            <Image
                              src="/icons/instagram.svg"
                              alt={
                                process.env.NEXT_PUBLIC_SITENAME + " Instagram"
                              }
                              width={20}
                              height={20}
                              loader={Local}
                            ></Image>
                          </div>
                        </a>
                      </Link>
                    )}

                    {Menu.social.facebook && (
                      <Link href={Menu.social.facebook}>
                        <a
                          title={process.env.NEXT_PUBLIC_SITENAME + " Facebook"}
                          target={"_blank"}
                          rel="nofollow"
                        >
                          <div className="w-9 h-9 relative rounded-full bg-white p-2">
                            <Image
                              src="/icons/facebook.svg"
                              alt={
                                process.env.NEXT_PUBLIC_SITENAME + " Facebook"
                              }
                              width={20}
                              height={20}
                              loader={Local}
                            ></Image>
                          </div>
                        </a>
                      </Link>
                    )}
                    {Menu.social.youtube && (
                      <Link href={Menu.social.youtube}>
                        <a
                          title={process.env.NEXT_PUBLIC_SITENAME + " Youtube"}
                          target={"_blank"}
                          rel="nofollow"
                        >
                          <div className="w-9 h-9 relative rounded-full bg-white p-2">
                            <Image
                              src="/icons/youtube.svg"
                              alt={
                                process.env.NEXT_PUBLIC_SITENAME + " Youtube"
                              }
                              width={20}
                              height={20}
                              loader={Local}
                            ></Image>
                          </div>
                        </a>
                      </Link>
                    )}
                  </>
                ) : null}
              </div>
              <Link href={"https://www.tursab.org.tr/tr/ddsv"}>
                <a rel="nofollow">
                  <Image
                    src={"/icons/tursab.svg"}
                    width={80}
                    height={42}
                    loader={Local}
                    alt={"Türsab Logo"}
                  />
                </a>
              </Link>
            </div>

            <div className="flex mt-8 justify-between ml:justify-start sm:justify-center lg:justify-start">
              {Menu ? (
                <>
                  <Link href={Menu.static.Page1.url}>
                    <a
                      title={Menu.static.Page1.title}
                      className="px-2 ml:px-7 py-2 font-bold text-xs ml:text-13 text-white tracking-widest bg-brand-color-primary rounded-sm leading-10 ml:mr-3"
                    >
                      {Menu.static.Page1.kisa_baslik}
                    </a>
                  </Link>
                  <Link href={Menu.static.Page2.url}>
                    <a
                      title={Menu.static.Page2.title}
                      className="px-2 ml:px-7 py-2 font-bold text-xs ml:text-13 text-black tracking-widest bg-white rounded-sm leading-10"
                    >
                      {Menu.static.Page2.kisa_baslik}
                    </a>
                  </Link>
                </>
              ) : null}
            </div>
          </div>

          <div className="flex w-full flex-col text-center mm:text-left mm:gap-x-4 mm:flex-row-reverse justify-end sm:justify-center mt-5 ml:gap-x-12 lg:w-7/12 lg:flex-row lg:mt-0 gap-y-5 mm:gap-y-0">
            {Menu &&
              Menu.data.map((val, key) => {
                return <FooterLinkGroup key={key} val={val}></FooterLinkGroup>;
              })}
          </div>
        </div>
        <div className="flex mt-11 justify-center lg:justify-start">
          <div className="w-5/12 hidden lg:flex items-center gap-x-7">
            <Link
              href={
                "https://etbis.eticaret.gov.tr/sitedogrulama/6067570258707468"
              }
            >
              <a target={"_blank"} rel={"nofollow"}>
                <Image
                  src="/icons/etbis.svg"
                  width={62}
                  height={71}
                  loader={Local}
                  alt={"Etbis QR Kod"}
                />
              </a>
            </Link>
            <Link href={"https://www.tursab.org.tr/tr/ddsv"}>
              <a rel="nofollow" target={"_blank"}>
                <Image
                  src={"/icons/tursab.svg"}
                  width={190}
                  height={108}
                  loader={Local}
                  alt={"Türsab Logo"}
                />
              </a>
            </Link>
          </div>
          <div className="w-full ml:w-auto lg:w-7/12 flex items-center justify-between flex-col-reverse lg:flex-row gap-x-3">
            <div className="flex items-center lg:mt-0 relative h-14 w-full">
              <Image
                src={"/images/payments.svg"}
                objectFit={"contain"}
                layout={"fill"}
                alt={""}
                loader={Local}
              />
            </div>
            <div className="flex items-center mt-9 lg:mt-0 relative h-10 flex-shrink w-48 lg:ml-auto">
              <Image
                src={"/images/visamaster.png"}
                objectFit={"contain"}
                layout={"fill"}
                alt={""}
                loader={Local}
              />
            </div>
            {process.env.NEXT_PUBLIC_SITE != 3 && (
              <div className="bg-green-bg  rounded flex items-center ml:pl-3 flex-col ml:flex-row justify-center text-center ml:text-left flex-shrink-0">
                <span
                  className="text-white text-xxs font-medium leading-2 ml:pr-5 py-3 ml:py-0 w-40"
                  dangerouslySetInnerHTML={{
                    __html: footerPaymentLinkButtonText,
                  }}
                ></span>
                <Link href={"https://web.villavillam.com.tr/odeme/"}>
                  <a
                    className="bg-yellow-400 w-full ml:w-auto h-full pl-3 pr-3 bg-green-bg-2 flex items-center rounded-tr rounded-br py-3 flex-col"
                    target={"_blank"}
                  >
                    <div className="mr-2 hidden ml:block">
                      <Image
                        src={"/icons/ssl-secure.svg"}
                        width="43"
                        height="48"
                        loader={Local}
                        alt={"Ssl"}
                      />
                    </div>
                    <span className="text-white font-bold leading-4">
                      {pay}
                    </span>
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

export default Footer;
