import React from "react";
import { useTranslation } from "../../context/TranslationContext";

const CopyRight = () => {
  const { copyRight } = useTranslation();
  return (
    <div className="container py-4 lg:py-8 flex justify-between mb-16 lg:mb-0 flex-col items-center lg:flex-row">
      <span className="font-medium text-xs text-brand-black">{copyRight}</span>
      <span>BöcekSoft</span>
    </div>
  );
};

export default CopyRight;
